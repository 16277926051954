import { authInteractor } from "~/core";
import { useUserStore } from "~/stores";
import { updateGtagConfig } from '~/plugins/gtag.client'
let store;
export const useAuth = () => {
  return {
    // 获取客户信息
    setUserInfo: async () => {
      store = store || useUserStore();
      let [userInfo, err] = await authInteractor.getUserInfoApi(
        store.accessToken,
        store.refreshToken
      );
      if (userInfo) {
        store.setUserInfo(userInfo);
        return true;
      }
      return false;
    },

    // 正常登录
    doLogin: async (email, password, verifyCode, browserType) => {
      store = store || useUserStore();
      const [userInfo, err] = await authInteractor.loginApi(
        email,
        password,
        verifyCode,
        browserType
      );
      if (err) {
        return "API_ERROR";
      }
      if (userInfo?.type == 2) {
        store.setUserInfo({
          ...userInfo,
          email,
          password,
          userId: "" //这里需要清空userId
        });
        return false;
      }
      if (userInfo !== null && typeof userInfo === "object") {
        // 设置用户信息
        store.setUserInfo({
          ...userInfo,
          email,
          password
        });
        store.setAccessToken(userInfo.accessToken);
        store.setRefreshToken(userInfo.refreshToken);
        return true;
      }
      return false;
    },

    goHome() {
      let store = useUserStore();
      if (store.userInfo?.userId) {
        if (
          !store.oneTimeLoginEmail ||
          store.userInfo.email === store.oneTimeLoginEmail
        ) {
          navigateTo("/");
        }
      } else {
        return false;
      }
    },

    // 判断登录用户账号是否相同
    isSameLoginUser({ email }) {
      store = store || useUserStore();
      // 判断是否登录
      if (store.userInfo?.email !== email) {
        // 不同账号，未登录，需要重新
        return false;
      }
      return true;
    },
    // 是否已经登录
    async isLoggedIn() {
      store = store || useUserStore();
      if (!store.accessToken || !store.refreshToken) {
        return false;
      }
      let [userInfo, err] = await authInteractor.getUserInfoApi(
        store.accessToken,
        store.refreshToken
      );
      if (userInfo) {
        // 更新配置
        updateGtagConfig({ 'user_name': userInfo?.email, is_logged_in:"1" })
        return true;
      }
      updateGtagConfig({ 'user_name': '', is_logged_in:"0" })
      return false;
    }
  };
};
